html {
  background-color: rgba(0, 50, 56, 1);  /* #01243d */
}

.App {
  background-color: rgba(0, 50, 56, 1);  /* #01243d */
  width: 100%;
}

body {
    overflow: hidden;
    overflow-y: scroll;
}

.lower-screen {
  padding: 0 3em;
  max-width: 1200px;
  margin: auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-container {
  overflow:hidden;
  margin:auto;
  width:100%;
}

h3 {
  color:white!important;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*
  Mobile queries
*/

@media only screen and (max-width: 800px) {
  .App-Header {
    padding: 15vh 2vh 2vh 2vh!important;
  }
  
  .page-menu-title p {
    display:none!important;
  }
}