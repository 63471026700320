@keyframes slideleft {
    0% {
        left: -100%;
    }
    100% {
        right: -100%;
    }
}
@keyframes mymove
{
    0% { left: -100%;opacity: 1;}
    20% { left: 0%;opacity:1}
    90% { left: 50%;opacity:1}
    100% {left: 110%;opacity:0}
}
@keyframes mymove1
{
    0% { left: -100%;opacity:1}
    30% { left: 0%;opacity:1}
    90% { left: 70%;opacity:1}
    100% {left: 110%;opacity:0}
}

@keyframes mymoveright1
{
    0% { right: -100%;opacity:1}
    20% { right: 10%; filter: invert(.3);opacity:1}
    90% { right: 90%; filter: invert(.8);opacity:1}
    100% {right: 110%;opacity:0}
}

@keyframes mymoveright
{
    0% { right: -100%;opacity:1}
    20% { right: 0%; filter:invert(.5);opacity:1}
    60% { right: 55%; filter: invert(1);opacity:1}
    100% {right: 110%;opacity:0}
}


.cloud-wrapper {
    display: flex;
    flex-direction: row;
    
}

.cloud-container {
    opacity:0;
    display: inline-block;
    flex-direction: row;
    position: absolute;
    animation-timing-function: linear;
    overflow: visible;
}

.slideleft0 {
    animation: 16s linear 12s mymove;
    right: -100%;
}

.slideleft1 {
    animation: 28s linear 0s mymove1;
    right: -100%;
}

.slideright0 {
    animation: 28s linear 0s mymoveright;
    opacity:0;
    right: -100%;
}

.slideright1 {
    animation: 16s linear 12s mymoveright1;
    right: -100%;
}

.cloud-bump {
    border-radius: 100%;
}

@keyframes fadein {
    from {
      opacity:0;
    }
  
    to {
        opacity:1;
    }
  }

