.about-me-container {
    padding: 2em 0em 2em 0em;
    color: rgba(255,255,255,.8);
    max-width: 200vh;
    display: flex;

}

.about-me-text {
    font-size: 18px;
    padding: 1em 1em 1em 1em;
    width: 70%;
    display:inline-block;
}

.about-me-image {
    width: 150px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-me-image img {
    border-radius: 100%;
    width: 100%;
}
@media only screen and (max-width: 600px) {

    li {
        width: 100%!important;
    }

    .contact-menu-wrapper {
     
        height: 40vh;
        top: 10vh;
    }

    .project-tile-wrapper p {
        display:none;
    }

    .project-tile-wrapper img {
            width: calc(100% - 10px)!important;
            height: auto!important;
            margin:5px;
        }

}  


.about-me-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    justify-content: center;
}

.skills-item {
    font-size: 16px;
    display: inline-block;
    padding: 8px;
    background-color: rgba(0,0,0,.5);
    border-radius: 25px;
    margin: 1vh;
}

.skills-item-wrapper {
    display:inline-block;
    width:50%;
}

.skills-list li {
    width: calc(50% - 10px);
    font-size: 16px;
    list-style: none;
    float: left;
    padding: 15px;
    box-shadow: 1px 2px 0px 1px rgba(0,0,0,.5);
    margin: 5px;
    background-color: rgba(255,255,255,.1);
    border-radius: 10px 0px 10px 0px;
    min-height: 160px;
    overflow:hidden;
    text-overflow:ellipsis;
}



p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
 }

 .skills-container {
    width: 100%;
}

.skills-container li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item-details {
    width: 70%;
}