.rs-panel-shaded {
    background-color: rgba(0,0,0,.3);
}

.rs-panel-heading {
    color:white!important;
}

.project-description-panel {
    width: calc(100% - 240px);
    display: inline-block;
    vertical-align: top;
    color:white;
}

.project-tile-wrapper {
    padding: 1em 0;
}

.rs-panel-group .rs-panel + .rs-panel::before
{
    display:none;
}

.other-projects-container .rs-panel-group .rs-panel {
    border: none;
    background-color: rgba(0,0,0,.3);
    margin: 1em 0 1em 0;
}

.project-tag-container {
    padding: 1em;
}

.rs-panel-bordered {
    border:none!important;
}