.form-row {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

input {
  color: rgba(200, 200, 200, 1) !important;
}

label {
  color: rgba(160, 160, 160, 1) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.3) !important;
  background-color: none !important;
}

textarea {
  color: rgba(200, 200, 200, 1) !important;
}

.form-row.single .MuiTextField-root {
  width: 95%;
  margin: auto;
}

.form-row.submit {
  margin: 1% 2.5%;
}

.form-row.split .MuiTextField-root {
  width: 45%;
  margin: 0% 2.5% 0% 2.5%;
}
.thank-you-message {
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-row.loading {
  justify-content: center !important;
  align-items: center !important;
}

.form-wrapper {
  width: 100%;
}

.half {
  width: 50%;
}
.full {
  width: 100%;
}

h1 {
  color: white !important;
}

.screen-wrapper {
  margin: 0 7em 2em 7em;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 15px;
}
