
.landscape-wrapper {
    height: 100vh;
    padding-top: 125px;
    pointer-events:none;
    margin-bottom: 150px;
    position: sticky;
}

.greeting-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.inspired-by {
    position:absolute;
    bottom: 0;
    color:white;
    padding:10px;
    right:0;
}


.greeting-intro, .greeting-name, .tag-line {
    font-size: 32px;
    color: white;
    padding: 10px;
    font-weight: 600;
    opacity: 0;
}

.greeting-wrapper {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 200px;
    left: 15%;
}

.project-description {
    -webkit-line-clamp: unset!important;
}


.mountain {
    width: 0;
    height: 0;
    border-left: 200px solid transparent;
    border-right: 200px solid transparent;
    border-bottom: 400px solid #35537d;
    position: absolute;
    bottom: 0;
    left: calc(50% - 200px);
    z-index: 5;
    opacity: 0;
    animation: fadein 1s 3s forwards;
}

.sun {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: #F8B195;
    left: calc(50% - 25px);
    bottom: 300px;
    z-index: 1;
    position: absolute;
    opacity: 0;
    animation: fadein 2s 3s forwards;
}

.greeting-intro {
    animation: fadein 1.5s forwards;
}

.greeting-name {
    width: 250px;
    animation: fadein 1.5s 1.5s forwards;
}
.tag-line {
    animation: fadein 4s 1.5s forwards;
}

