.contact-menu-item-wrapper {
    cursor:pointer;
}


.contact-menu-item-wrapper .contact-menu-icon:hover {
    color:white;
}

.contact-menu-icon svg {
    font-size:2em;
}