.contact-menu-wrapper {
    position: fixed;
    align-items: center;
    color: white;
    display: inline-grid;
    padding: 1em;
    height: 50vh;
    margin-top: 25vh;
    right:0vh;
    right:-25vh;
    animation: slide-left 3s forwards;
    z-index: 9999999;
}

.contact-menu-icon {
    display: inline-block;
    padding: 1em;
    border-radius: 2em;
    color: rgba(66, 189, 171, 1);
    color: rgba(215, 245, 66, 1);
  }
