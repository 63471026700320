.page-menu-wrapper {
  position: fixed;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  color: white;
  padding: 1em;
  width: 100%;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
  background-color: rgba(0, 50, 56, 1);  /* #01243d */
  z-index: 99999;
  width: 100%;
  top:-25vh;
  animation: slide-down-menu 1s 3s forwards;
}

.page-menu-item-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-menu-wrapper.up {
  animation: slide-down-menu .5s forwards!important; 
}

.page-menu-wrapper.down {
  animation: slide-up-menu .5s forwards!important; 
}

.menu-item {
    position: relative;
}

@keyframes slide-right {
    from {
      left: -10vh;
      opacity:0;
    }
  
    to {
      left:0;
        opacity:1;
    }
  }

  @keyframes slide-down-menu {
    from {
      top: -25vh;
    }
  
    to {
      top:0vh;
    }
  }

  @keyframes slide-up-menu {
    from {
      top: 0vh;
    }
  
    to {
      top:-25vh;
    }
  }
.page-menu-icon{
  display: inline-block;
  color: #d7f542;
  font-size: 24px;
  margin: 0 8px;
  line-height: 24px;
}
.page-menu-title {
    display: inline-block;
    color: #42bdab;
    font-weight: 800;
    font-size: 1em;
}

.tw-logo {
  width: 100px;
}

.page-menu-container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.tw-logo-icon {
  width: 125px;
}

.circle {
  stroke-dasharray: 650;
  stroke-dashoffset: 0;
  animation: draw-circle 3s 3s forwards;
}

.logo-container {
  justify-content: center;
  align-items: center;
  margin: auto;
}

.logo-image {
  width: 100px;
}

