    .Primary {
        color:#132e2a;
    }
    .Secondary {
        color:#42bdab;
    }
/*
        Primary: #132e2a
        Secondary: #42bdab
        Accent: rgba(215, 245, 66, 1)
    */
    .App-Header {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 6em 0 6em;
      font-size: calc(10px + 2vmin);
      color: white;
      justify-content: center;
  }
  .App-Body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  
  .App-Body-Title {
    text-align:left;
    padding:15px;
  }
  
.App-Header h1 {
      font-size: 5em;
      margin:0;
      line-height:.8em;
  }
.App-Header h2 {
    font-size: 2em;
    color: rgba(215, 245, 66, .5);
    padding: .5em 0 .5em 0;
}
.App-Header h5 {
    font-size: .7em;
}
.App-Header .transparent-text {
  font-weight: 300;
  color: rgba(255, 255, 255, .8);
}


  .about-me-summary { 

  }
  .greeting-text {
    animation: 1s slide-right;
    position:relative;
  }
  .name-text {
    top:-1000vh;
    animation: slide-down 1s 1s forwards;
    position:relative;
  }
  .slogan-text {
    position: relative;
    right: -1000vh;
    animation: slide-left 1s 2s forwards;
  }

  .short-about-me-text {
    top: 1000vh;
    width: 80%;
    position: relative;
    animation: slide-up 1s 3s forwards;
  }


  @keyframes slide-down {
    from {
      top: -100vh;
    }
  
    to {
      top:0;
    }
  }

  @keyframes slide-left {
    from {
      right: -100vh;
    }
  
    to {
      right: 0;
    }
  }

  @keyframes slide-right {
    from {
      left: -100vh;
    }
  
    to {
      left:0;
    }
  }

  @keyframes slide-up {
    from {
      top: 100vh;
    }
  
    to {
      top:0;
    }
  }