.work-details-header {
    font-size:18px;
    font-weight:600;
    color: white;
}

.work-details-description {
    font-size:14px;
    color: white;
}

.work-dates {
    color:white;
}

.work-container-header {
    color: white;
    font-size: 32px;
}

.panel-container {
    display: flex;
    flex-direction: row;
}

.panel-container.reverse {
    flex-direction: row-reverse
}

.featured-projects-container .rs-panel-heading {
    font-weight:bold;
    font-size:24px;
}