.icon-image-wrapper {
    width: 97px;
    height: 97px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-image {
    border-radius: 100%;
    width: 75px;
}
  .timeline-item {
      margin: 16px 0;
  }

  .work-container .rs-timeline-item-content {
    margin-left: 60px;
  }