div.slide-right {
    overflow:hidden;
  }
  

.page-menu-title p {
  color:white;
}


  @keyframes slide-right {
    from {
      margin-left: -1em;
      width: 300%; 
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
  .page-menu-item-wrapper {
      cursor:pointer;
    }

    .up .page-menu-item-wrapper {
      animation:none!important;
      opacity:1!important;
    }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 100%;
    }
  }