.services-list li {
    width: calc(50% - 10px);
    font-size: 16px;
    list-style: none;
    float: left;
    padding: 15px;
    box-shadow: 1px 2px 0px 1px rgba(0,0,0,.5);
    margin: 5px;
    background-color: rgba(255,255,255,.1);
    border-radius: 10px 0px 10px 0px;
    min-height: 225px;
    overflow:hidden;
    text-overflow:ellipsis;
    color:white;    
    display: flex;
}


.item-header {
    font-size:18px;
    font-weight:bold;
    color: white;
}

.item-icon {

    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.icon-image {
    width: 100px;
}

.item-details-container {
    padding: 10px;
    width: 70%;
}

.clickable-item {
    cursor: pointer;
}

.not-is-visible {
    visibility: hidden;
}

.is-visible {

}

